<template>
    <div class="flex border border-beige-dark rounded-lg w-full h-full overflow-hidden">
        <div class="flex-none bg-beige w-32 md:w-40 flex items-center justify-center text-4xl relative">
			<img 
				v-if="userPassed.picture"
				:src="fileKeyToUrl( userPassed.picture )"
				alt="Logo"
				class="object-cover w-full h-full"
			/>
            <i 
				v-else
                class="fa fa-user" 
                :class="userPassed.departments == 'admin' ? 'fa-user-shield' : 'fa-user-tie'"
            />
            
            <div 
                v-if="userPassed.status == 'FORCE_CHANGE_PASSWORD'"
                class="bg-orange-400 text-white w-6 h-6 rounded-full flex items-center justify-center absolute font-bold text-base left-0 m-4">
                
                !
            </div>
        </div>
        <div class="flex-grow p-8">
            <h3 class="text-lg font-bold leading-tight">{{userPassed.given_name || userPassed.given_name ? userPassed.given_name + ' ' + userPassed.family_name : userPassed.email}}</h3>
            <div class="opacity-75 mb-3 leading-tight">{{userPassed.email}}</div>
            
			
            <div v-if="userPassed.departments == 'admin'">
                Administrator
            </div>
            <div v-else>
                <!-- Normal bruker -->
                <div v-if="departments">
                    <div 
                        v-if="departments.filter( d => userPassed.departments.includes( d.departmentId ) ).length < 3"
                        class="flex">
                        
                        <div 
                            v-for="department in departments.filter( d => userPassed.departments.includes( d.departmentId ) )"
                            :key="'userDepartment_'+department.departmentId"
                            class="mr-2">
                            
                            {{department.name}}
                        </div>
                    </div>
                    <div 
                        v-else
                        class="flex">
                        
                        <div class="bg-beige px-2 rounded">
                            {{ departments.filter( d => userPassed.departments.includes( d.departmentId ) ).length }}
                        </div>
                        <div class="ml-2">
                            {{ departments.filter( d => userPassed.departments.includes( d.departmentId ) ).length ? 'avdelinger' : 'avdeling'}}
                        </div>
                    </div>
                </div>
            </div>
			
			<div 
				v-if="isSuperAdmin && userPassed.comment"
				class="bg-yellow-200 font-bold p-2 mt-2">
				
				{{ userPassed.comment }}
			</div>
			
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        userPassed: {
            type: Object,
            required: true,
        }
    },
    
    computed:{
        departments(){
            return this.$store.getters.getDepartments;
        },
        
        // userDepartments(){
        //     if (!this.departments || this.departments == 'admin' || !this.userPassed || !this.userPassed.departments || !this.userPassed.departments.length ) {
        //         return
        //     }
        // 
        //     return this.departments.filter( d => this.userPassed.departments.includes( d.departmentId ) );
        // },
    },
    
}
</script>

<style lang="scss">
</style>